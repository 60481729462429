<script setup lang="ts">
const { value: liveInUs } = useField('liveInUs')
</script>

<template>
  <div class="text-2xl sm:text-3xl text-red-500 text-center">
    Take this quiz - see if you qualify
  </div>

  <div class="text-3xl sm:text-4xl text-center">
    Do you live in {{ ip.lookup?.stateName || 'America' }}?
  </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="liveInUs = true"
    >
      Yes
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="darkBlue"
      @click="liveInUs = false"
    >
      No
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="gray"
      class="col-span-2"
      @click="liveInUs = null"
    >
      Skip
    </Button>
  </div>

  <div class="sm:text-lg text-gray-600 text-center">
    Check what plans are available near you!
  </div>
</template>
