<script setup lang="ts">
import imageStep1 from '../../../assets/v5-typ-1.png'
import imageStep2 from '../../../assets/v5-typ-2.png'
import imageStep3 from '../../../assets/v5-typ-3.png'
import imageStep4 from '../../../assets/v5-typ-4.png'

useHead({
  title: `You've been matched with an agent in your area - ${brand.name}`,
})

analytics.page({
  name: 'Medicare TYP 2.0',
  category: 'TYP',
  product: 'medicare',
})

const steps = [
  {
    image: imageStep1,
    title: 'Step 1',
    description: `${brand.name} guides you through the important questions to ask your licensed sales agent.`,
  },
  {
    image: imageStep2,
    title: 'Step 2',
    description: `${brand.name} matches you with the right licensed sales agent near you.`,
  },
  {
    image: imageStep3,
    title: 'Step 3',
    description:
      'You & your licensed sales agent check your eligibility to enroll and select a plan that meet your needs.',
  },
  {
    image: imageStep4,
    title: 'Step 4',
    description: 'Enjoy your coverage.',
  },
]
</script>

<template>
  <Layout class="container">
    <!-- success -->
    <div class="space-y-4 py-12 text-center">
      <div class="text-3xl sm:text-4xl font-bold">
        {{ forms.v2?.firstname }}, it's time to
        <Underline> compare & possibly save! </Underline>
      </div>
      <div class="text-lg sm:text-xl">
        <template v-if="ip.lookup?.cityName && ip.lookup?.stateCode">
          The next step is to compare plans available to you in
          {{ ip.lookup?.cityName }}, {{ ip.lookup?.stateCode }}.
        </template>
        <template v-else>
          The next step is to compare the plans & benefits available in your
          zipcode ({{ forms.v2?.zipcode }}).
        </template>
      </div>
    </div>

    <MediaAlphaAd
      :placementId="
        query.utm_source?.toLowerCase() === 'ma'
          ? 'oLXPHoGj8cfc6Rk20iBxNYto4sr1mw'
          : 'xB51YFMkBb4D9ycHYv9YNkBUyjbbUA'
      "
      :user="{
        zip: forms.v2?.zipcode,
        email: forms.v2?.email,
        phone: forms.v2?.primaryphone,
        contact:
          forms.v2?.firstname &&
          forms.v2?.lastname &&
          `${forms.v2?.firstname} ${forms.v2?.lastname}`,
        currently_insured: forms.v2?.medicareab ? 1 : null,
      }"
    />

    <!-- how it works -->
    <div class="py-24">
      <div class="space-y-4 mb-12 text-center">
        <div class="text-3xl sm:text-4xl font-bold">
          How {{ brand.name }} Works
        </div>
        <div class="text-lg sm:text-xl">
          We make it simple to find a Medicare plan with possible additional
          benefits.
        </div>
      </div>

      <div class="space-y-4 max-w-xl mx-auto">
        <div
          v-for="step in steps"
          :key="step.title"
          class="flex p-4 space-x-4 bg-slate-200 rounded-md"
        >
          <img :src="step.image" class="w-32 h-32 rounded-md" />
          <div>
            <div class="text-lg font-bold mb-1"> {{ step.title }} </div>
            <div class="text-gray-700"> {{ step.description }} </div>
          </div>
        </div>
      </div>
    </div>

    <!-- divider -->
    <div class="container">
      <div class="h-px bg-gray-200"></div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["m10"],
    "smid": "MULTI-PLAN_EHWEB092201_C",
    "robots": "noindex"
  }
}
</route>
