<template>
  <Layout footer="2024">
    <div class="container py-16">
      <div>
        Page benefits: ["giveback", "otc", "dental", "hearing", "drug",
        "vision"]
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["m10"],
    "benefits": ["giveback", "otc", "dental", "hearing", "drug", "vision"]
  }
}
</route>
