<script setup lang="ts"></script>
<template>
  <ClickToCall :props="{ ctcLocation: 'last-step' }" v-slot="{ handleClick }">
    <a
      :href="phone.uri"
      @click="handleClick"
      class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] flex w-full font-semibold = rounded-2xl"
    >
      <div
        class="flex gap-4 text-sm bg-white rounded-l-xl px-3 p-2 flex-grow items-center"
      >
        <img
          src="../assets/USA_flag.svg"
          alt="United States flag"
          class="max-w-8"
        />
        <div>{{ phone.formatted }} <br />TTY 711 24/7</div>
      </div>

      <div
        class="uppercase bg-green-600 rounded-r-xl p-2 text-white text-center flex items-center"
      >
        CALL NOW
      </div>
    </a>
  </ClickToCall>
</template>
