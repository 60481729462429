<script setup lang="ts">
const { value: start } = useField('start')
</script>

<template>
  <div class="rounded bg-green-600 border-0 -mt-4">
    <Button
      type="submit"
      wide
      variant="undefined"
      class="border-0 text-white !text-sm font-bold text-center"
      @click="start = true"
    >
      Premiums Start From $0! <br />
      Start Quiz Now To Check Eligibility
    </Button>
  </div>
</template>
