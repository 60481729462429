<script setup lang="ts">
import logoHumana from '../../assets/carrier-humana.svg'
import logoAnthem from '../../assets/carrier-anthem.svg'
import logoUnited from '../../assets/carrier-united.svg'
import logoCignaGray from '../../assets/carrier-cigna-gray.svg'
import logoHumanaGray from '../../assets/carrier-humana-gray.svg'
import logoBlueCrossGray from '../../assets/carrier-blue-cross-gray.svg'
import logoAnthemGray from '../../assets/carrier-anthem-gray.svg'
import logoAetnaGray from '../../assets/carrier-aetna-gray.svg'
import logoUnitedGray from '../../assets/carrier-united-gray.svg'

const router = useRouter()

const { handleSubmit } = useForm()

const onSubmit = handleSubmit((values) => {
  router.push(`/go-4-benefits/?zip=${values.zip}`)
})

const tiles = [
  {
    id: 'humana',
    logo: logoHumana,
    detail: 'Monthly Payment: <br /> $35.87',
    title: 'Medicare Plan A - Details',
    rating: 5,
  },
  {
    id: 'anthem',
    logo: logoAnthem,
    detail: 'Monthly Payment: <br /> $43.78',
    title: 'Medicare Plan C - Details',
    rating: 5,
  },
  {
    id: 'unitedHealthcare',
    logo: logoUnited,
    detail: 'Monthly Payment: <br /> $47.56',
    title: 'Medicare Plan F - Details',
    rating: 5,
  },
]

const carriers = [
  {
    id: 'cigna',
    image: logoCignaGray,
  },
  {
    id: 'humana',
    image: logoHumanaGray,
  },
  {
    id: 'blueCrossBlueShield',
    image: logoBlueCrossGray,
  },
  {
    id: 'anthem',
    image: logoAnthemGray,
  },
  {
    id: 'aetna',
    image: logoAetnaGray,
  },
  {
    id: 'unitedHealthcare',
    image: logoUnitedGray,
  },
]
</script>

<template>
  <Layout hideHeader>
    <!-- hero -->
    <div class="bg-[#3037e9]">
      <div class="container py-12 space-y-8 md:max-w-screen-md">
        <div class="text-white text-center space-y-4">
          <h1 class="text-3xl font-semibold">
            Compare 2024 Medicare Plans and Save
          </h1>
          <div class="text-lg">
            Save on Medicare Deductibles, Co-Pays and Other Out-of-Pocket
            Expenses
          </div>
        </div>

        <form
          @submit="onSubmit"
          class="grid grid-cols-[repeat(auto-fit,minmax(15.5rem,1fr))] items-start gap-3"
        >
          <Field
            type="zip"
            name="zip"
            rules="required"
            placeholder="Enter ZIP Code"
            class="text-center"
          />
          <Button wide type="submit" class="whitespace-nowrap">
            Get free quotes
          </Button>
        </form>

        <div class="space-y-2">
          <RouterLink
            v-for="tile in tiles"
            :key="tile.id"
            to="/go-4-benefits"
            class="bg-white rounded p-4 flex gap-4"
          >
            <div class="space-y-1">
              <img :src="tile.logo" class="w-16 h-4 object-contain" />
              <div v-html="tile.detail" class="text-xs text-gray-500"></div>
              <div>
                <div class="text-xs text-gray-500"> Medicare rating: </div>
                <div class="flex mt-0.5">
                  <RatingStars :rating="tile.rating" :max-range="5" />
                </div>
              </div>
            </div>

            <div class="flex-1 space-y-2">
              <div class="text-sm"> {{ tile.title }} </div>
              <div class="space-y-1">
                <div class="h-3 rounded bg-gray-200"></div>
                <div class="h-3 rounded bg-gray-200 w-1/2"></div>
              </div>
              <div
                class="inline-block bg-[#3037e9] py-0.5 px-1.5 rounded-md text-white text-sm"
              >
                Plan Details
              </div>
            </div>
          </RouterLink>
        </div>
      </div>
    </div>

    <!-- carriers -->
    <div class="bg-gray-200">
      <div class="container py-12 space-y-8">
        <div class="text-xl text-center text-gray-500">
          Compare Plans & Rates
        </div>

        <div class="flex flex-wrap justify-center gap-4">
          <img
            v-for="carrier in carriers"
            :key="carrier.id"
            :src="carrier.image"
            class="w-24 h-8 object-contain"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["m10"],
    "robots": "noindex"
  }
}
</route>
