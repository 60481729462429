<script setup lang="ts">
const start = Date.now()
const now = useNow()

const timer = useDateFormat(
  computed(() => Math.max(start + 120000 - now.value.getTime(), 1000)),
  'mm:ss'
)
</script>

<template>
  <div
    v-if="
      forms.call4AddedBenefits2024?.liveInUs !== false &&
      forms.call4AddedBenefits2024?.haveMedicare !== false
    "
    class="text-2xl sm:text-3xl text-red-500 text-center"
  >
    You have pre-qualified.
  </div>

  <div class="text-3xl sm:text-4xl text-center">
    Finish your application with a licensed insurance agent.
  </div>

  <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />

  <div class="text-lg sm:text-xl font-medium text-center">
    Your agent is waiting.
    <br />
    Call in the next <span class="text-red"> {{ timer }} </span>
  </div>
</template>
