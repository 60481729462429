<template>
  <div
    class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white p-4 space-y-6"
  >
    <div class="text-xl sm:text-2xl text-red text-center font-medium">
      Check Eligibility
    </div>

    <div class="text-xl sm:text-2xl text-center font-medium">
      Where do you live?
    </div>

    <Field
      type="zip"
      name="zipcode"
      rules="required"
      placeholder="Enter ZIP Code"
      class="text-center"
    />

    <FormButtonNext> Continue </FormButtonNext>
    <FooterPhone4
      text="Live Agent"
      ctc-location="medicare-premium-reduction-listing-zipCode-step"
    />
  </div>
</template>
