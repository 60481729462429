<script setup lang="ts">
import { AdsResponse, MediaAlphaSingleAdType } from '~/modules/ma'
import { PlanRowView } from '~/modules/plan'

const plans = ref<PlanRowView[]>()
const loading = ref<boolean>(true)
const error = ref<boolean>(false)

const ads = ref<MediaAlphaSingleAdType[]>([])

enterExperiment('lowAsZeroDentalPremiumsCallOption')
const state = ref<string>('')
onMounted(async () => {
  await until(() => ip.lookup).toBeTruthy()
  const zipCode =
    forms.lowAsZeroDentalPremiums?.zipcode ?? ip.lookup?.postalCode ?? '90212'
  state.value = ip.lookup?.stateName ?? 'America'

  try {
    plans.value = await getPlans({
      zipCode,
      carrier_groups: [],
      amount: 100,
      starting_from: 0,
    })
  } catch (e) {
    error.value = true
  } finally {
    loading.value = false
  }
  const body = {
    data: {},
    ip: ip.lookup?.value,
    local_hour: new Date().getHours(),
    placement_id: 'loyhRoUY8koY5vZgUSMZtFFQKTVnPg',
    ua: navigator.userAgent,
    ua_language: navigator.language,
    url: location.href,
    version: 17,
    sub_2: 'segment anonymous id value',
    sub_3: getSession().id,
  }

  try {
    let url = import.meta.env.VITE_WEBSITE_API_URL + `/mediaalpha/ads`
    const response = await axios.post<AdsResponse>(url, body)
    ads.value = response?.data.ads
  } catch (e) {
    console.error('Error on retrieve ads', (e as any).toString())
  }
})
</script>

<template>
  <div
    class="[box-shadow:0_0_6px_rgba(0,0,0,0.3)] rounded bg-white p-4 flex flex-col gap-4"
  >
    <!-- <template v-if="exp?.lowAsZeroDentalPremiumsCallOption?.option1"> -->
    <template v-if="loading && !error">
      <div class="flex items-center justify-center">
        <Spinner />
      </div>
    </template>
    <template v-else-if="!loading && error">
      <div class="text-xl text-red text-center font-medium">
        {{ state }} Plans Found with Added Dental Benefit
      </div>
      <DoubleClickToCall />
      <div class="text-xl text-center font-medium">
        Click to call
        <span class="whitespace-nowrap flex items-center justify-center gap-2">
          <div class="relative flex w-4 h-4">
            <div
              class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"
            >
            </div>
            <div
              class="relative inline-flex rounded-full h-4 w-4 bg-green-500"
            ></div>
          </div>
          Licensed Insurance Agent
        </span>
      </div>
    </template>
    <template v-else>
      <div
        class="text-xl text-red text-center font-medium"
        v-if="plans?.length && plans?.length > 0"
      >
        {{ plans?.length }} Plans Available to You
      </div>
      <div class="text-xl text-red text-center font-medium" v-else>
        No Plans Found
      </div>

      <DoubleClickToCall />
      <div class="text-xl text-center font-medium">
        Click to call
        <span class="whitespace-nowrap flex items-center justify-center gap-2">
          <div class="relative flex w-4 h-4">
            <div
              class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"
            >
            </div>
            <div
              class="relative inline-flex rounded-full h-4 w-4 bg-green-500"
            ></div>
          </div>
          Licensed Insurance Agent
        </span>
      </div>
    </template>
  </div>
  <!-- </template> -->
  <!-- <template v-else>
    <div class="flex flex-col items-center gap-4">
      <div class="text-xl text-center font-medium">
        Click for More Options
      </div>
      <ClickToCall :props="{ ctcLocation: 'last-step' }">
        <template #text>
          {{ phone.formatted }}
          <br />
          711, 24/7
        </template>
      </ClickToCall>
      <div class="text-xl text-center font-medium">
        Licensed Insurange Agent Available
      </div>
    </div>

    <div class="flex flex-col items-center gap-4" v-if="ads && ads.length">
      <MediaAlphaSingleAd :ad="ads[0]" />
      <MediaAlphaSingleAd v-if="ads.length > 1" :ad="ads[1]" />
    </div>
  </template> -->
</template>
