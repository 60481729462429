<script setup lang="ts">
import aepTimeline from '../../assets/medicare-benefits-aep-timeline.png'
import oepTimeline from '../../assets/medicare-benefits-oep-timeline.png'
import sepTimeline from '../../assets/medicare-benefits-sep-timeline.png'
import iepTimeline from '../../assets/medicare-benefits-iep-timeline.png'
import testimonial1 from '../../assets/testimonial-1.jpeg'
import testimonial2 from '../../assets/testimonial-2.jpeg'

import iconCoinWallet from '../../assets/mb/coin-wallet.png'
import iconMoneyHand from '../../assets/mb/money-hand.png'
import iconRx from '../../assets/mb/rx.png'
import iconRun from '../../assets/mb/run.png'
import iconDoctor from '../../assets/mb/doctor.png'
import iconTransportation from '../../assets/mb/transportation.png'
import iconPersonCheck from '../../assets/mb/person-check.png'
import iconPlusMoney from '../../assets/mb/plus-money.png'
import iconHealthFiles from '../../assets/mb/health-files.png'
import iconEar2 from '../../assets/mb/ear.png'

import iconCapsules from '../../assets/mb/icon-capsules.svg'
import iconDental from '../../assets/mb/icon-dental.svg'
import iconEar from '../../assets/mb/icon-ear.svg'
import iconEye from '../../assets/mb/icon-eye.svg'
import iconProfile from '../../assets/mb/icon-profile.svg'

import newsYahooFinance from '../../assets/mb/news-yahoo-finance.svg'
import newsBusinessInsider from '../../assets/mb/news-business-insider.svg'
import newsForbes from '../../assets/mb/news-forbes.svg'
import newsFox from '../../assets/mb/news-fox.svg'
import newsNbc from '../../assets/mb/news-nbc.svg'

import iconShoppingCart from '../../assets/mb/icon-shopping-cart.svg'
import iconShieldCheck from '../../assets/mb/icon-shield-check.svg'

const featuredOn = [
  {
    id: 'yahooFinance',
    image: newsYahooFinance,
  },
  {
    id: 'businessInsider',
    image: newsBusinessInsider,
  },
  {
    id: 'forbes',
    image: newsForbes,
  },
  {
    id: 'fox',
    image: newsFox,
  },
  {
    id: 'nbc',
    image: newsNbc,
  },
]

const benefits = [
  {
    icon: iconCoinWallet,
    text: 'Monthly Plan Premiums',
  },
  {
    icon: iconMoneyHand,
    text: 'Primary Care Doctor Copays',
  },
  {
    icon: iconRx,
    text: 'Annual Rx Deductibles',
  },
  {
    icon: iconRun,
    text: 'Fitness Club Allowance',
  },
  {
    icon: iconDoctor,
    text: 'Specialist Copays',
  },
  {
    icon: iconTransportation,
    text: 'Doctor Visits Transportation Allowance',
  },
  {
    icon: iconPersonCheck,
    text: 'OOP',
  },
  {
    icon: iconPlusMoney,
    text: 'OTC Benefit',
  },
  {
    icon: iconHealthFiles,
    text: 'Part D Premium',
  },
  {
    icon: iconEar2,
    text: 'Copay on Hearing Benefits',
  },
]

const bundleYourCoverage = [
  {
    icon: iconProfile,
    text: 'Medicare Part A & B',
  },
  {
    icon: iconCapsules,
    text: 'Prescription Drugs',
  },
  {
    icon: iconDental,
    text: 'Routine Dental Services',
  },
  {
    icon: iconEye,
    text: 'Routine Vision Services',
  },
  {
    icon: iconEar,
    text: 'Routine Hearing Exams',
  },
]

const periods = [
  {
    id: 'oep',
    image: oepTimeline,
  },
  {
    id: 'sep',
    image: sepTimeline,
  },
  {
    id: 'iep',
    image: iepTimeline,
  },
  {
    id: 'aep',
    image: aepTimeline,
  },
]

const why = [
  {
    icon: iconShoppingCart,
    title: 'Compare Medicare Advantage Plans Made Easy',
    description:
      'A dedicated licensed insurance agent will work with you to make sure you find Medicare Advantage Plans in Your Area.',
  },
  {
    icon: iconShieldCheck,
    title: 'Tailored Plans Just For You',
    description:
      'Based on your needs, a dedicated licensed insurance agent will discuss your options and put together a plan thats right for you.',
  },
]

const notStaging = import.meta.env.MODE !== 'staging'

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})
</script>

<template>
  <Layout footer="2024">
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>

    <!-- hero -->
    <div>
      <div
        class="container py-8 grid items-center justify-items-center gap-8 md:grid-cols-3"
      >
        <div class="relative w-48 h-48 flex items-center justify-center">
          <div class="bg-gray-200 w-40 h-40 rounded-full"></div>
          <StateMap
            v-if="ip.lookup?.stateCode"
            :state-code="ip.lookup.stateCode"
            class="absolute inset-0 text-red"
          />
          <img
            v-else
            src="../../assets/us-map-red.svg"
            class="absolute inset-0 w-full h-full"
          />
        </div>

        <div class="md:col-span-2">
          <div class="text-3xl font-medium">
            Learn More About Medicare Advantage Plans in
            {{ ip.lookup?.stateName || 'America' }} Today!
          </div>
          <div class="text-lg mt-2 mb-8">
            Find a Medicare Advantage Plan with the benefits you want and the
            coverage you need.
          </div>
          <div class="bg-white rounded-md shadow p-4 max-w-xl">
            <MedicareBenefitsForm />
          </div>
        </div>
      </div>
    </div>

    <!-- logos -->
    <div class="bg-darkBlue text-white">
      <div class="container py-8">
        <div class="text-lg text-center"> As featured on </div>

        <div class="flex flex-wrap justify-center gap-4 mt-8">
          <img
            v-for="item in featuredOn"
            :key="item.id"
            :src="item.image"
            class="w-24 h-8"
          />
        </div>
      </div>
    </div>

    <!-- benefits -->
    <div>
      <div class="container py-8">
        <div class="text-2xl font-medium text-center">
          Medicare Advantage Plan Benefits in
          {{ ip.lookup?.stateName || 'America' }} have never been better
        </div>
        <div class="text-lg mt-2 mb-8 text-center">
          Find a Medicare Advantage Plan that fits your individual needs. Plan
          benefits vary depending on your zip code and eligibility. Some plans
          may include benefits such as:
        </div>

        <div class="grid gap-2 md:grid-cols-2">
          <div
            v-for="item in benefits"
            :key="item.text"
            class="bg-white rounded-md shadow p-4 flex items-center gap-4"
          >
            <img :src="item.icon" width="64" height="64" />
            <div class="flex-1"> {{ item.text }} </div>
          </div>
        </div>
      </div>
    </div>

    <!-- bundle your coverage -->
    <div class="bg-gray-200">
      <div class="container py-8">
        <div class="text-2xl font-medium text-center">
          Medicare Advantage: Streamline Your Coverage with a Single
          Comprehensive Plan
        </div>
        <div class="text-lg mt-2 mb-8 text-center">
          Medicare Advantage Plans, also known as Medicare Part C, provide a
          comprehensive addition to Original Medicare. Offering added benefits
          like hearing, dental, vision, or prescription drug coverage often
          found in separate plans.
        </div>

        <div class="grid justify-items-center">
          <div class="space-y-1">
            <div
              v-for="item in bundleYourCoverage"
              :key="item.text"
              class="flex items-center gap-4"
            >
              <img :src="item.icon" width="32" height="32" />
              <div class="flex-1"> {{ item.text }} </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- enrollment periods -->
    <div>
      <div class="container py-8">
        <div class="text-2xl font-medium text-center">
          2024 Important Enrollment Periods in
          {{ ip.lookup?.stateName || 'America' }}
        </div>

        <div class="my-8 space-y-8 max-w-xl mx-auto">
          <div
            v-for="period in periods"
            :key="period.id"
            class="bg-white rounded-md shadow py-6 px-4"
          >
            <img :src="period.image" />
          </div>
        </div>

        <div class="max-w-screen-md mx-auto">
          <ClickToCall tty :props="{ ctcLocation: 'medicare-benefits' }" />
        </div>
      </div>
    </div>

    <!-- form -->
    <div class="bg-gray-200">
      <div class="relative">
        <img
          src="../../assets/medicare-benefits-form.jpeg"
          width="768"
          height="347"
          class="mx-auto w-xl"
        />
        <div class="absolute inset-0 bg-gradient-to-t from-gray-200"></div>
      </div>
      <div class="container py-8">
        <div class="text-2xl font-medium text-center">
          Ready to Find Your Plan?
        </div>
        <div class="text-lg mt-2 mb-8 text-center">
          Plan premiums starting at $0/mo1 in many areas
        </div>

        <div class="bg-white rounded-md shadow p-4 max-w-screen-md mx-auto">
          <MedicareBenefitsForm />
        </div>

        <div class="flex items-center justify-center gap-6 mt-8">
          <img src="../../assets/bbb-badge.svg" width="97" height="37" />
          <img src="../../assets/comodo-badge.svg" width="107" height="34" />
        </div>
      </div>
    </div>

    <!-- why -->
    <div>
      <div class="container py-8 md:max-w-screen-md">
        <div class="text-2xl font-medium text-center">
          Why Choose {{ brand.name }}?
        </div>

        <div class="mt-8 grid gap-8 md:grid-cols-2">
          <div v-for="item in why" :key="item.title">
            <img :src="item.icon" width="24" height="24" />
            <div class="text-lg font-medium mt-2 mb-1"> {{ item.title }} </div>
            <div class="text-gray-600"> {{ item.description }} </div>
          </div>
        </div>
      </div>
    </div>

    <!-- testimonials -->
    <div class="bg-darkBlue" v-if="notStaging && isPaidUser">
      <div class="container py-8">
        <Testimonials
          :testimonials="[
            {
              photo: testimonial1,
              name: 'John Peterson',
              text: 'Great help from Laura, and a plan with extras! Laura was so helpful to me. She took the time to explain my new health plan. Now, I\'ve got a plan that is just like my old one, but better. The best part is it includes dental coverage! I am so happy I won\'t have to worry about my teeth anymore. Thanks to Laura and Humana!',
            },
            {
              photo: testimonial2,
              name: 'Mary Sullivan',
              text: 'Fantastic service and Part B giveback! I had a great talk with Steven about my Medicare Advantage plan. He was really nice and explained everything clearly. I\'m really excited about the Part B giveback feature in my plan. It feels good knowing I\'ll be getting money back. Thank you, Steven!',
            },
          ]"
        />
      </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["m10"],
    "benefits": ["giveback", "otc", "dental", "hearing", "drug", "vision"],
    "robots": "noindex",
    "smid": "MULTI-PLAN_HLWEB0923104_M"
  }
}
</route>
