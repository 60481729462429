<script setup lang="ts">
useHead({
  title: `Call ${brand.name} for Medicare quotes`,
})

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})
</script>

<template>
  <Layout header="2025" footer="2025" class="container">
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-xl sm:text-3xl font-bold text-center">
        Individuals with Medicare A&B are getting added Medicare Advantage Plan
        benefits like dental, vision, or money back in the form of reduced
        Medicare Part B premiums with some plans from carriers like Humana and
        UnitedHealthcare®
      </div>

      <img
        src="../../assets/helpline-seniors-hands-up.png"
        width="1254"
        height="836"
        class="w-[90%] sm:w-[400px] mx-auto"
      />

      <div class="text-xl text-center">
        Not all plans offer all of these benefits. Benefits may vary by carrier
        and location. Limitations and exclusions may apply.
      </div>

      <Form
        id="call4AddedBenefits"
        class="!max-w-3xl p-4 border-2 border-gray-300 rounded-xl shadow-lg"
        scrollBehavior="keepPos"
        :steps="[
          { id: 'medicare' },
          { id: 'medicaid' },
          { id: 'loading' },
          { id: 'call', completed: true },
        ]"
        product="medicare"
      />
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["m10"],
    "benefits": ["giveback", "otc"],
    "robots": "noindex",
    "smid": {
      "m10": "MULTI-PLAN_HLWEB0624212_M"
    }
  }
}
</route>
