<script setup lang="ts">
if (forms.subscribe4Benefits?.phone) {
  analytics.track('smsConsentGiven', {
    phone: forms.subscribe4Benefits.phone,
    origin: 'subscribe4Benefits',
  })
}
</script>

<template>
  <div class="text-3xl sm:text-4xl pt-24 text-center">
    Thanks, I'll get to work and send you a new text soon.
  </div>
</template>
