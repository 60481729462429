<template>
  <div class="text-3xl sm:text-4xl text-center">
    Speak with a licensed insurance agent.
  </div>

  <ClickToCall :props="{ ctcLocation: 'last-step' }">
    <template #text>
      Call {{ phone.formatted }} <br />
      TTY 711, 24/7
    </template>
  </ClickToCall>

  <div class="text-lg sm:text-xl font-medium text-center">
    Your agent is waiting.
  </div>
</template>
