<script setup lang="ts">
import type { Ref } from 'vue'

const data = inject('data') as Ref<{
  [key: string]: any
} | null>
</script>

<template>
  <div class="text-2xl sm:text-3xl">
    Hi, {{ data?.user?.first_name ?? 'there' }}
  </div>

  <div
    class="py-6 px-4 bg-white [box-shadow:0_1px_5px_rgba(0,0,0,0.2)] rounded space-y-2"
  >
    <div class="flex items-center gap-4">
      <Icon i-heroicons-outline:check class="text-blue" />
      <div class="flex-1 text-lg">
        I'm Emily. I can help you use your Medicare benefits.</div
      >
    </div>
    <div class="flex items-center gap-4">
      <Icon i-heroicons-outline:check class="text-blue" />
      <div class="flex-1 text-lg">
        Do you want my help finding the benefits you actually need?
      </div>
    </div>
    <div class="flex items-center gap-4">
      <Icon i-heroicons-outline:check class="text-blue" />
      <div class="flex-1 text-lg">
        I will check your plan for benefits like grocery allowance, otc
        benefits, dental, vision, and money back (premium giveback).
      </div>
    </div>
  </div>

  <FormButtonNext> Sign up (FREE) </FormButtonNext>
</template>
