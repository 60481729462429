<script setup lang="ts">
import seniors from '~/assets/helpline-seniors-hands-up.png'
import dentalBenefits from '~/assets/dental-benefits.png'

useHead({
  title: `Call ${brand.name} for Medicare quotes`,
})

const isMounted = useMounted()

const isPaidUser = ref(true)
const image = ref({
  src: seniors,
  width: '1254',
  height: '836',
})
const isMobile = ref(true)

const showDefaultExperience = computed(() => {
  return !isMobile.value
  // && import.meta.env.MODE === 'production'
})

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
  isMobile.value = window.innerWidth < 640
  if (!showDefaultExperience.value) {
    enterExperiment('getDentalBenefits')
    if (exp.value?.getDentalBenefits?.dental) {
      image.value = {
        src: dentalBenefits,
        width: '1254',
        height: '836',
      }
    }
  }
})
</script>

<template>
  <Layout hideHeader footer="addedBenefits2024" class="container">
    <template v-if="!isPaidUser" #banner>
      <Banner> New to Medicare? Have Medicaid? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-6 py-6">
      <div class="text-xl sm:text-3xl font-bold text-center">
        <template v-if="showDefaultExperience">
          Are you shopping for Medicare Insurance plans? A licensed insurance
          agent can check your eligibility.
        </template>
        <template v-else>
          <template v-if="exp?.getDentalBenefits?.default">
            Americans with Medicare A&B are getting
            <span class="text-red">
              added benefits like dental, vision, or money back
            </span>
            in the form of reduced Medicare Part B premiums
          </template>
          <template v-if="exp?.getDentalBenefits?.dental">
            <strong>Attention:</strong> Americans with Medicare A&B are getting
            added benefits like
            <span class="text-red w-full block text-2xl font-base">
              dental, vision, or money back
            </span>
          </template>
        </template>
      </div>

      <template v-if="isMounted">
        <template v-if="showDefaultExperience">
          <img
            src="../../assets/helpline-seniors-hands-up.png"
            width="1254"
            height="836"
            class="w-[90%] sm:w-[400px] mx-auto"
          />
        </template>
        <template v-else>
          <img
            :src="image.src"
            :width="image.width"
            :height="image.height"
            class="w-[90%] sm:w-[400px] mx-auto"
          />
        </template>
      </template>
      <div class="text-xl font-base text-center">
        in the form of reduced Medicare Part B premiums with some Medicare
        Advantage plans from carriers like Humana and UnitedHealthcare®.
      </div>
      <Form
        id="call4AddedBenefits2024"
        class="!max-w-3xl p-4 border-2 border-gray-300 rounded-xl shadow-lg"
        scrollBehavior="keepPos"
        :steps="[
          { id: 'us' },
          { id: 'medicare' },
          { id: 'medicaid' },
          { id: 'loading' },
          { id: 'call', completed: true },
        ]"
        product="medicare"
      />
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["m10"],
    "benefits": ["giveback", "otc"],
    "robots": "noindex",
    "smid": {
      "bh": "MULTI-PLAN_EHWEB082320_M",
      "m10": "MULTI-PLAN_EHWEB082325_M",
      "med10": "MULTI-PLAN_EHWEB082321_M",
      "boomer": "MULTI-PLAN_EHWEB082322_M"
    },
    "xsmid": {
      "bh": "MULTI-PLAN_EHWEB082320_M",
      "m10": "MULTI-PLAN_EHWEB082325_M",
      "med10": "MULTI-PLAN_EHWEB082321_M",
      "boomer": "MULTI-PLAN_EHWEB082322_M"
    }
  }
}
</route>
