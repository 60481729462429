<script setup lang="ts">
useHead({
  title: `Medicare Premium Reduction Listing 2096 - ${brand.name}`,
})

enterExperiment('medicarePremiumReductionListingImage')
</script>

<template>
  <Layout header="simple5" footer="giveback" class="container">
    <template #banner>
      <Banner> New to Medicare? On Medicaid? Moving? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-4 py-6">
      <div class="text-xl sm:text-2xl text-red text-center font-medium">
        Attention: Medicare Part B Premiums Increased to $2096/yr in 2024
      </div>

      <img
        v-if="exp?.medicarePremiumReductionListingImage?.card"
        class="w-52 md:w-80 mx-auto"
        src="../../assets/card-medicare-b.png"
        alt="Medicare Card with $174 green stamp"
      />
      <img
        v-else
        src="../../assets/woman-with-check.jpg"
        class="w-52 md:w-80 mx-auto"
        alt="Elderly smiling woman holding a check"
      />
      <div class="text-xl sm:text-2xl text-center font-medium">
        CHECK ELIGIBILITY FOR PART B PREMIUM REDUCTION BENEFIT
      </div>
      <Form
        id="medicarePremiumReductionListing2096"
        class="!max-w-3xl"
        scrollBehavior="keepPos"
        :steps="[
          { id: 'zipcode' },
          { id: 'medicareab' },
          { id: 'results' },
          { id: 'call', completed: true },
        ]"
        product="medicare"
      />
    </div>
    <div class="h-px bg-gray-200"> </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["giveback"],
    "brands": ["m10"],
    "smid": "MULTI-PLAN_HLWEB0124132_M",
    "xsmid": "MULTI-PLAN_HLWEB0124132_M",
    "robots": "noindex"
  }
}
</route>
