<script setup lang="ts">
import { PlanRowView } from '~/modules/plan'

useHead({
  title: `Low as zero dental premiums - ${brand.name}`,
})

const isProd = import.meta.env.MODE === 'production'

enterExperiment('lowAsZeroDentalPremiums')

type LoadDataParams = {
  startingFrom?: number
  amount?: number
}

const zipCode = computed(() => {
  return ip.lookup?.postalCode ?? '90312'
})

const loading = ref(false)
const error = ref(false)
const currentPlans = ref<PlanRowView[]>([])
const loadMoreVisible = ref(true)

const loadData = async (params?: LoadDataParams) => {
  const startingFrom = params?.startingFrom ? params.startingFrom : 0
  const amount = params?.amount ? params.amount : 10
  loading.value = true
  error.value = false
  if (zipCode.value) {
    const plans = await getPlans({
      zipCode: zipCode.value,
      starting_from: startingFrom,
      amount: amount,
    })
    currentPlans.value = [...currentPlans.value, ...plans]
    loading.value = false
    if (plans.length === 0) {
      loadMoreVisible.value = false
      return
    }
  }
}
const isPaidUser = ref(false)

onMounted(() => {
  loadData({ startingFrom: 0, amount: 10 })
  isPaidUser.value = getSessionEnhanced().isPaidUser
})

const isMobile = useIsMobile()
</script>

<template>
  <Layout
    header="simple5"
    :hide-header="isMobile"
    footer="giveback"
    class="container"
  >
    <template #banner v-if="!isMobile">
      <Banner> New to Medicare? On Medicaid? Moving? </Banner>
    </template>
    <div class="max-w-3xl mx-auto space-y-4 py-6">
      <div class="text-xl text-center font-medium">
        2024 Medicare Advantage Plans with Dental Coverage you Could Get:
        <span class="font-bold">
          Dentures, Some Major Dental, or Routine Dental Visits
        </span>
      </div>

      <img
        src="../../assets/dental-low-copay-6.png"
        width="1024"
        height="1024"
        class="w-[90%] sm:w-[400px] mx-auto"
        alt="Happy Senior lady in a dental chair"
      />
      <div class="text-sm text-center font-medium">
        From plans like UnitedHealthcare®, Humana, Aetna, Anthem Blue Cross &
        Blue Shield, and Wellcare
      </div>
      <UseForm v-slot="{ values }">
        <Form
          id="lowAsZeroDentalPremiums"
          class="!max-w-3xl p-4"
          scrollBehavior="keepPos"
          :steps="[
            { id: 'start' },
            { id: 'medicareab' },
            { id: 'medicaid' },
            {
              id: 'call',
              completed: true,
              if: isMobile && values?.haveMedicare === true,
            },
            {
              id: 'clicks',
              if: isProd ? !isMobile || values?.haveMedicare === false : false,
            },
          ]"
          product="medicare"
        />
      </UseForm>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "benefits": ["dental"],
    "brands": ["m10"],
    "robots": "noindex",
    "smid": "MULTI-PLAN_HLWEB0124141_M"
  }
}
</route>
