<script setup lang="ts">
const { value: carrier } = useField('carrier')
</script>

<template>
  <FormProgressSteps />

  <div class="text-3xl sm:text-4xl text-center"> Pick your carrier </div>

  <div class="grid grid-cols-2 gap-4">
    <Button
      wide
      size="sm"
      type="submit"
      variant="red"
      @click="carrier = 'Humana'"
    >
      Humana
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="red"
      @click="carrier = 'UnitedHealthcare'"
    >
      UnitedHealthcare®
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="red"
      @click="carrier = 'Wellcare'"
    >
      Wellcare
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="red"
      @click="carrier = 'Aetna'"
    >
      Aetna
    </Button>
    <Button
      wide
      size="sm"
      type="submit"
      variant="red"
      @click="carrier = 'Other'"
    >
      Other
    </Button>
  </div>
</template>
