<script setup lang="ts">
useHead({
  title: `Register for Benefits - ${brand.name}`,
})

const route = useRoute()
const slug = route.query.slug as string

const loading = ref(true)
const error = ref(false)
const data = ref<{
  [key: string]: any
} | null>(null)

provide('data', data)

const load = () => {
  loading.value = true
  error.value = false

  axios
    .get(`${import.meta.env.VITE_COMMIE_URL}/api/website/pages/${slug}`)
    .then((res) => {
      const responseData = res.data.data
      data.value = responseData
    })
    .catch(() => {
      error.value = true
    })
    .finally(() => {
      loading.value = false
    })
}

onMounted(() => {
  load()
})
</script>

<template>
  <Layout header="emily" class="container flex flex-col">
    <div class="flex-1 py-16">
      <div v-if="loading" class="bg-white rounded-md shadow p-8">
        <Spinner class="mx-auto" />
      </div>
      <Form
        v-if="!loading"
        id="subscribe4Benefits"
        :steps="[
          { id: 'start' },
          { id: 'zipcode' },
          { id: 'carrier' },
          { id: 'reminders' },
          { id: 'thanks' },
        ]"
        product="medicare"
      />
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["m10"],
    "robots": "noindex"
  }
}
</route>
