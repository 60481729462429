<template>
  <Layout>
    <div class="container py-16">
      <div> Page benefits: ["otc"] </div>
    </div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["m10"],
    "benefits": ["otc"]
  }
}
</route>
