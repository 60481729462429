<script setup lang="ts">
import type { Ref } from 'vue'

const data = inject('data') as Ref<{
  [key: string]: any
} | null>
</script>
<template>
  <FormProgressSteps />

  <div class="text-3xl sm:text-4xl text-center"> What's your ZIP code? </div>

  <Field
    type="zip"
    name="zipcode"
    label="ZIP code"
    rules="required"
    :initialValue="data?.user?.zipcode ?? query.zip"
  />

  <FormButtonNext />
</template>
